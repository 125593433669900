<template>
  <div>
    <div class="gotoApp">
      <img src="http://img.ailinzn.com/static/banner4.png" alt="" class="App">
      <div class="lxwm">
        <div>新闻详情</div>
      </div>
    </div>
    <div class="list">
      <div style="  min-height: 34vh;
  width: 76vw;">
        <div style="font-size: 30px">{{list.articleName}}</div>
        <div style="display: flex;justify-content: center;margin: 20px 0">
          <div style="margin-right: 50px;color: #999999">发表时间： {{list.createTime}}</div>
          <div style="color: #999999">作者 ：{{list.articleAuthor}}</div>
        </div>
        <div v-html="list.articleContent"></div>
      </div>
    </div>
    <footterComponent></footterComponent>
  </div>
</template>
<script>
import footterComponent from '../../components/footterComponent.vue';

export default {
  components: {
    footterComponent,
  },
  data() {
    return {
      url: 'http://api-smart.ailinzn.cn',
      list: {},
     articleId:""
    };
  },
  created() {
    this.articleId = this.$router.currentRoute.query.id
  },
  mounted() {
    this.getNews()
  },
  methods: {
    getNews() {
      let self = this
      this.$axios({
        method: 'GET',
        url: self.url + '/web/article/detail/'+ self.articleId,
      })
          .then(res => {
            console.log(res)
            if (res.data.code == 200) {
              self.list = res.data.data
            }
          })
          .catch(err => {
            console.log(err)
          })
    }
  }
}
</script>
<style scoped>
.gotoApp {
  height: 9.33333rem;
  position: relative
}

.lxwm {
  position: absolute;
  top: 0;
  //background: rgb(30 28 28 / 39%); width: 100%; height: 100%; text-align: center; color: white; font-size: 1.53333rem; //letter-spacing: 1rem; font-family: MiSans; display: flex; justify-content: center; align-items: center;
}

.App {
  width: 100%;
  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

}

.shop {
  margin-bottom: 0.2rem;
  font-size: 0.42667rem;
  text-align: left;
  font-family: MiSans;
  font-weight: 400;
  color: #141414;
}

.titleImage {
  width: 7.05333rem;
  height: 4.06667rem;
  font-size: 0.42667rem;
  font-family: MiSans;
  font-weight: 400;
  color: #939393;
  line-height: 0.64rem;
  overflow: hidden;
  border-radius: 8px;
}

.time {
  font-size: 0.3rem;
  color: #999999;
}
</style>
