<template>
  <div class="header" id="tel">
    <div class="container flex">
      <div class="logo">
        <img src="../assets/logo.png" alt="">
      </div>
      <div class="classify">
        <div v-for="item in tabs" :key="item.id">
          <span @click="gotoPages(item.path)">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: [
        {
          id: '1',
          name: '首页',
          path: '/'
        },
        {
          id: '2',
          name: '关于我们',
          path: 'aboutUs'
        },
        {
          id: '3',
          name: '产品展示',
          path: 'product'
        },
        {
          id: '4',
          name: '工程案例',
          path: 'cases'
        },
        {
          id: '5',
          name: '新闻动态',
          path:'News'
        },
        {
          id: '6',
          name: '联系我们',
          path: 'shopAddress'
        },
      ]
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollTop)
  },
  methods: {
    scrollTop() {
      let scrollY = window.pageYOffset
      console.log(window.pageYOffset)
      let tel = document.getElementById("tel")
      if (scrollY > 150) {
        tel.style.transition = ' background 0.3s ease-in 1s,color 0.3s ease-in 1s'
        tel.style.background = '#333'
        tel.style.color = '#fff'
      } else {
        tel.style.transition = ' background 0.3s ease-in 1s,color 0.3s ease-in 1s'
        tel.style.background = 'transparent'
        tel.style.color = '#333'

      }

    },
    gotoPages(path) {
      if (path) {
        this.$router.push({path: path},()=>{})
        // this.$router.push({ path: path })
      }
    }

  }
}
</script>
<style scoped>
.header {
  background: transparent;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 99999;
}

.container {
  max-width: 80vw;
  height: 1.2rem;
  margin: 0 auto;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 1.33333rem;
  height: 1.33333rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 3rem;
}

.classify {
  display: flex;
  width: 400px;
  font-size: 0.42667rem;
  justify-content: space-evenly;
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.classify span:hover {
  color: #ff6900;
}
</style>
