<template>
  <div class="footer">
    <div>
      鲁ICP备2021027004号-3 | COPYRIGHT (©) 2023 艾琳智能科技有限公司
    </div>
    <div class="container">
      <div class="left">
        <div class="f1">网站信息及说明</div>
        <div class="f2">隐私政策</div>
        <div class="f2">法律声明</div>
        <div class="f2" @click="gotoMap">网站地图</div>
      </div>
      <div class="center">
        <div class="f1">社交媒体</div>
        <div class="f3">
          <img src="http://img.ailinzn.com/static/gzh.jpg" alt="" style="width:100px;height:100px">
          <img src="http://img.ailinzn.com/static/xdgj.png" alt="" style="width:100px;height:100px">
          <img src="http://smart.ailinzn.com/static/img/app%E4%B8%8B%E8%BD%BD.30c6ca9.png" alt="" style="width:100px;height:100px">
        </div>
      </div>
      <div class="right">
        <div class="f1">联系我们</div>
        <div class="f4" style="font-size:24px"> 400-607-7689</div>
        <div class="f4"> 中国·山东·潍坊市寒亭区嘉实科技城</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    gotoMap() {
      this.$router.push({ path: '/shopAddress' })
    }
  }
}
</script>
<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #525A65;
  color: #fff;
  font-size: 12px;
  padding-top: .5rem;
}

.container {
  line-height: 1.2rem;
  text-align: center;
  display: flex;
  text-align: left;
}

.f1 {
  font-size: 0.35rem;
  font-family: MiSans;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 35px;
}

.f2 {
  color: #A2A7AD;
}

.f4 {
  color: #A2A7AD;
}

.f3 {
  width: 9.33333rem;
  display: flex;
  justify-content: space-between;
}

.left,
.center {
  margin-right: 2.33333rem;
}
</style>
