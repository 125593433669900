<template>
  <div id="app">
    <headerComponent></headerComponent>
    <router-view></router-view>
  </div>
</template>

<script>
import headerComponent from './components/headerComponent.vue';

export default {
  name: 'app',
  components: {
    headerComponent,
  }
}
</script>

<style>
* {
  margin: 0;
}

#app {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #2c3e50;
  background: #F7F7F7;
  cursor: pointer;
  font-family: MiSans;
}
</style>
