<template>
  <div>
    <div class="gotoApp">
      <img src="http://img.ailinzn.com/static/banner4.png" alt="" class="App">
      <div class="lxwm">
        <div>新闻动态</div>
      </div>
    </div>
    <div class="list">
      <div style="  min-height: 30vh;
  width: 76vw;">
        <div v-for="item in list" style="display:flex;width:100%;justify-content: space-evenly;margin-bottom:40px;"
          @click="goDetails(item)">
          <div class="titleImage">
            <img :src="item.thumbnail" alt="" class="App">
          </div>
          <div style="display:flex;flex-direction:column;justify-content:space-evenly;width:45%;height:3.06667rem;">
            <div class="shop" style="margin:0.5rem 0">{{ item.articleName }}</div>
            <div class="shop" style="color:#939393;font-size:0.3rem">{{ item.articleBrief }}</div>
          </div>
          <div style="display:flex;flex-direction:column;justify-content:center;height:100%">
            <div class="time" style="margin:0.6rem 0">{{ item.createTime }}</div>
            <!--           <div style="display:flex" class="time">-->
            <!--             <div>|</div>-->
            <!--             <div>&ndash;&gt;</div>-->
            <!--           </div>-->
          </div>
        </div>
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
            :page-sizes="[5, 10, 15]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <footterComponent></footterComponent>
  </div>
</template>
<script>
import footterComponent from '../../components/footterComponent.vue';

export default {
  components: {
    footterComponent,
  },
  data() {
    return {
      url: 'http://api-smart.ailinzn.cn',
      list: [],
      current: 1,
      total: 0,
      form: {
        type: '',
        limit: 5,
        page: 1
      }
    };
  },
  mounted() {
    this.getNews()
  },
  methods: {
    goDetails(item) {
      let self = this
      if (item.articleType == 3) {
        window.open(item.articleUrl, "_blank",);
      } else {
        this.$router.push({
          path: 'details', query: {
            id: item.id
          }
        }, () => {
        })
      }
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.getNews()
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getNews()
    },
    getNews() {
      let self = this
      this.$axios({
        method: 'POST',
        url: self.url + '/web/article/articleList',
        data: self.form
      })
        .then(res => {
          console.log(res)
          if (res.data.code == 200) {
            self.list = res.data.data.list
            self.total = res.data.data.totalCount
            self.currPage = res.data.data.currPage
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style scoped>
.gotoApp {
  height: 9.33333rem;
  position: relative
}

.lxwm {
  position: absolute;
  top: 0;
  background: rgb(30 28 28 / 39%);
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 1.53333rem;
  font-family: MiSans;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  width: 100%;
  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

}

.shop {
  margin-bottom: 0.2rem;
  font-size: 0.42667rem;
  text-align: left;
  font-family: MiSans;
  font-weight: 400;
  color: #141414;
}

.titleImage {
  width: 7.05333rem;
  height: 4.06667rem;
  font-size: 0.42667rem;
  font-family: MiSans;
  font-weight: 400;
  color: #939393;
  line-height: 0.64rem;
  overflow: hidden;
  border-radius: 8px;
}

.time {
  font-size: 0.3rem;
  color: #999999;
}
</style>
