<template>
  <div class="wrapper">
    <swiper ref="mySwiper" v-bind:options="swiperOptions" style="height:100%">
      <swiper-slide v-for="(item, index) in bannerList" v-bind:key="index">
        <img :src="item.thumbnail" alt="" class="banner">
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="product">
      <div class="container">
        <div>
          <div class="top">
            <div>国内一站式智能行业综合服务商</div>
            <div class="centerImg">
              <div class="img1"></div>
              <div class="img2"></div>
              <div class="img3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="abouts">
        <div>
          <div class="top" style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <div>关于我们</div>
            <div style="font-size:0.4rem;margin:20px 0;width:45%;height:3rem">
              艾琳科技集团是一家拥有自主知识产权的集居家智慧养老生活服务平台、智能家居、智能锁等物联网、人工智能技术应用、软硬件的研发设计、产销服务于一体的国家高新技术企业、科技型中小企业。拥有国内领先的AIOT智能研发平台。
            </div>
            <div class="mid">
              <div class="kuai">
                <div>
                  <img src="http://img.ailinzn.com/static/3.png" alt="" class="imgss">
                </div>
                <div style="position: relative">
                  <CountTo :startVal="startVal" :endVal="endVal" :duration="duration" class="plus" />
                  <svg t="1697094093692" class="icon" viewBox="0 0 1024 1024" version="1.1" style="position: absolute;"
                    xmlns="http://www.w3.org/2000/svg" p-id="4023" width="16" height="16">
                    <path
                      d="M832 469.333333H554.666667V192c0-25.6-17.066667-42.666667-42.666667-42.666667s-42.666667 17.066667-42.666667 42.666667V469.333333H192c-25.6 0-42.666667 17.066667-42.666667 42.666667s17.066667 42.666667 42.666667 42.666667H469.333333v277.333333c0 25.6 17.066667 42.666667 42.666667 42.666667s42.666667-17.066667 42.666667-42.666667V554.666667h277.333333c25.6 0 42.666667-17.066667 42.666667-42.666667s-17.066667-42.666667-42.666667-42.666667z"
                      fill="#221E1F" p-id="4024"></path>
                  </svg>
                </div>
                <div class="production">
                  客户
                </div>
              </div>
              <div class="kuai">
                <div>
                  <img src="http://img.ailinzn.com/static/8.png" alt="" class="imgss">
                </div>
                <div style="position: relative">
                  <CountTo :startVal="startVal" :endVal="endVal1" :duration="duration" class="plus plus1" />
                  <svg t="1697094093692" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4023" width="16" height="16" style="position: absolute;">
                    <path
                      d="M832 469.333333H554.666667V192c0-25.6-17.066667-42.666667-42.666667-42.666667s-42.666667 17.066667-42.666667 42.666667V469.333333H192c-25.6 0-42.666667 17.066667-42.666667 42.666667s17.066667 42.666667 42.666667 42.666667H469.333333v277.333333c0 25.6 17.066667 42.666667 42.666667 42.666667s42.666667-17.066667 42.666667-42.666667V554.666667h277.333333c25.6 0 42.666667-17.066667 42.666667-42.666667s-17.066667-42.666667-42.666667-42.666667z"
                      fill="#221E1F" p-id="4024"></path>
                  </svg>
                </div>
                <div class="production">
                  拥有软件著作权
                </div>
              </div>
              <div class="kuai">
                <div>
                  <img src="http://img.ailinzn.com/static/7.png" alt="" class="imgss">
                </div>
                <div style="position: relative">
                  <CountTo :startVal="startVal" :endVal="endVal2" :duration="duration" class="plus plus1" />
                  <svg t="1697094093692" class="icon" viewBox="0 0 1024 1024" version="1.1" style="position: absolute;"
                    xmlns="http://www.w3.org/2000/svg" p-id="4023" width="16" height="16">
                    <path
                      d="M832 469.333333H554.666667V192c0-25.6-17.066667-42.666667-42.666667-42.666667s-42.666667 17.066667-42.666667 42.666667V469.333333H192c-25.6 0-42.666667 17.066667-42.666667 42.666667s17.066667 42.666667 42.666667 42.666667H469.333333v277.333333c0 25.6 17.066667 42.666667 42.666667 42.666667s42.666667-17.066667 42.666667-42.666667V554.666667h277.333333c25.6 0 42.666667-17.066667 42.666667-42.666667s-17.066667-42.666667-42.666667-42.666667z"
                      fill="#221E1F" p-id="4024"></path>
                  </svg>
                </div>
                <div class="production">
                  现拥有专利
                </div>
              </div>
              <div class="kuai">
                <div>
                  <img src="http://img.ailinzn.com/static/1.png" alt="" class="imgss">
                </div>
                <div style="position: relative">
                  <CountTo :startVal="startVal" :endVal="endVal2" :duration="duration" class="plus plus1" />
                  <svg t="1697094093692" class="icon" viewBox="0 0 1024 1024" version="1.1" style="position: absolute;"
                    xmlns="http://www.w3.org/2000/svg" p-id="4023" width="16" height="16">
                    <path
                      d="M832 469.333333H554.666667V192c0-25.6-17.066667-42.666667-42.666667-42.666667s-42.666667 17.066667-42.666667 42.666667V469.333333H192c-25.6 0-42.666667 17.066667-42.666667 42.666667s17.066667 42.666667 42.666667 42.666667H469.333333v277.333333c0 25.6 17.066667 42.666667 42.666667 42.666667s42.666667-17.066667 42.666667-42.666667V554.666667h277.333333c25.6 0 42.666667-17.066667 42.666667-42.666667s-17.066667-42.666667-42.666667-42.666667z"
                      fill="#221E1F" p-id="4024"></path>
                  </svg>
                </div>
                <div class="production">
                  案例
                </div>
              </div>
            </div>
            <div class="more" @click="gotoPath('aboutUs')">了解更多</div>
          </div>
        </div>
      </div>
      <div style="width:99vw;background:white;margin:0 auto;padding:1rem 0 1rem">
        <div>
          <div class="top" style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <div>产品中心</div>
            <div class="list">
              <div class="products">
                <div class="productFlex">
                  <div class="product2 ppp">
                    <img :src="list[0].thumbnail" alt="" class="App">
                    <div class="detail">
                      <div class="cen">
                        <p class="names">{{ list[0].productName }}</p>
                        <p class="briefs">{{ list[0].brief }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="product2 ppp">
                    <img :src="list[1].thumbnail" alt="" class="App">
                    <div class="detail">
                      <div class="cen">
                        <p class="names">{{ list[1].productName }}</p>
                        <p class="briefs">{{ list[1].brief }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="product2 ppp">
                    <img :src="list[2].thumbnail" alt="" class="App">
                    <div class="detail">
                      <div class="cen">
                        <p class="names">{{ list[2].productName }}</p>
                        <p class="briefs">{{ list[2].brief }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="productFlex">

                  <div class="product2 ppp">
                    <img :src="list[3].thumbnail" alt="" class="App">
                    <div class="detail">
                      <div class="cen">
                        <p class="names">{{ list[3].productName }}</p>
                        <p class="briefs">{{ list[3].brief }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="product2 ppp">
                    <img :src="list[4].thumbnail" alt="" class="App">
                    <div class="detail">
                      <div class="cen">
                        <p class="names">{{ list[4].productName }}</p>
                        <p class="briefs">{{ list[4].brief }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="product2 ppp">
                    <img :src="list[5].thumbnail" alt="" class="App">
                    <div class="detail">
                      <div class="cen">
                        <p class="names">{{ list[5].productName }}</p>
                        <p class="briefs">{{ list[5].brief }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="more" @click="gotoPath('product')">更多产品</div>
            </div>
          </div>

        </div>
      </div>
      <div class="gotoApp">
        <img src="http://img.ailinzn.com/static/download.png" alt="" class="App">
        <div class="neiApp">
          <div class="appT">智慧生活智能掌控</div>
          <div class="buttons" @click="gotoDownload">Android下载</div>
        </div>
      </div>
      <div style="padding:1rem 0 3rem;width:98vw">
        <div class="top" style="display:flex;flex-direction:column;justify-content:center;align-items:center">
          <div>商务合作</div>
          <div class="dep">艾琳科技实现养老、安防、智能家居的全屋智能化，努力打造中国领先的智慧居家服务平台</div>
          <div class="list listB">
            <div class="listL">
              <div class="listLTop">
                <img :src="cases[0].thumbnail" alt="" class="App">
              </div>
              <div class="listLBottom">
                <div class="shop">{{ cases[0].projectName }}</div>
                <div class="shop" style="color:#666666;font-size:0.3rem">{{ cases[0].brief }}</div>
              </div>
            </div>
            <div class="listL">
              <div class="listLTop">
                <img :src="cases[1].thumbnail" alt="" class="App">
              </div>
              <div class="listLBottom">
                <div class="shop">{{ cases[1].projectName }}</div>
                <div class="shop" style="color:#666666;font-size:0.3rem">{{ cases[1].brief }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding:1rem 0 3rem;width:98vw">
        <div class="top" style="display:flex;flex-direction:column;justify-content:center;align-items:center">
          <div>新闻动态</div>
          <div class="list">
            <div v-for="item in lists" style="display:flex;width:100%;justify-content: space-evenly;margin-bottom:40px;"
              @click="goDetails(item)">
              <div class="titleImage">
                <img :src="item.thumbnail" alt="" class="App">
              </div>
              <div style="display:flex;flex-direction:column;justify-content:space-evenly;width:45%;height:3.06667rem;">
                <div class="shop" style="margin:0.5rem 0">{{ item.articleName }}</div>
                <div class="shop" style="color:#939393;font-size:0.3rem">{{ item.articleBrief }}</div>
              </div>
              <div style="display:flex;flex-direction:column;justify-content:center;height:100%">
                <div class="time" style="margin:0.6rem 0">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <div class="more" @click="gotoPath('News')">更多资讯</div>
        </div>
      </div>
      <footterComponent></footterComponent>

    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import footterComponent from '../../components/footterComponent.vue';

import CountTo from "vue-count-to";

export default {
  components: {
    swiper,
    CountTo,
    footterComponent,
    swiperSlide,
  },
  data() {
    return {
      url: 'http://api-smart.ailinzn.cn',
      lists: [],
      cases: [],
      current: 1,
      total: 0,
      form: {
        type: '',
        limit: 5,
        page: 1
      },
      bannerList: [],
      list: [],
      news: [],
      startVal: 0, // 开始值
      endVal: 10000, // 开始值
      endVal1: 500, // 开始值
      endVal2: 300, // 开始值
      duration: 3000, // 持续时间(毫秒为单位)
      timer: null, // 定时器
      swiperOptions: {
        loop: false,
        speed: 2000,
        effect: 'fade',
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 修改默认点击左右箭头样式
        // navigation: {
        // nextEl: ".swiper-button-next",
        //prevEl: ".swiper-button-prev",
        //},
      },
    };
  },
  mounted() {
    this.getBanners()
    this.getCase()
    this.getNews()
    this.getProducts()
  },
  methods: {
    gotoPath(path) {
      this.$router.push({ path: path }, () => {
      })
    },
    goDetails(item) {
      let self = this
      if (item.articleType == 3) {
        window.open(item.articleUrl, "_blank",);
      } else {
        this.$router.push({
          path: 'details', query: {
            id: item.id
          }
        }, () => {
        })
      }
    },
    gotoDownload() {
      window.open('http://img.ailinzn.com/f9a52291858b39936d376dddbf57c73f.apk', '_blank')
    },
    getNews() {
      let self = this
      this.$axios({
        method: 'POST',
        url: self.url + '/web/article/articleList',
        data: self.form
      })
        .then(res => {
          console.log(res)
          if (res.data.code == 200) {
            self.lists = res.data.data.list
            self.total = res.data.data.totalCount
            self.currPage = res.data.data.currPage
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCase() {
      let self = this
      this.$axios({
        method: 'GET',
        url: self.url + '/web/project/projectList',
      })
        .then(res => {
          if (res.data.code == 200) {
            self.cases = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBanners() {
      let self = this
      this.$axios({
        method: 'POST',
        url: self.url + '/web/show/showList',
        data: {
          type: '1'
        }
      })
        .then(res => {
          console.log(res)
          if (res.data.code == 200) {
            self.bannerList = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getProducts() {
      let self = this
      this.$axios({
        method: 'GET',
        url: self.url + '/web/product/productList',
        data: {}
      })
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            self.list = res.data.data
            console.log(self.list)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  destroyed() {
  }
}
</script>

<style scoped>
.container {
  max-width: 80vw;
  margin: 0 auto;
}

.top {
  font-size: 0.8rem;
  font-family: MiSans;
  font-weight: 500;
  color: #141414;
}

.wrapper {
  position: relative;
  height: 14.26667rem;
}

.wrapper .swiper-button-nextsp,
.wrapper .swiper-button-prevsp {
  position: absolute;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 22222;
}

.wrapper .swiper-pagination-bullet-active {
  background: yellow;
}

.banner {
  width: 100%;
  height: 100%;
}

.product {
  width: 100%;
  height: 100%;
  margin-top: 1.90667rem;
}

.centerImg {
  display: flex;
  justify-content: center;
}

.centerImg .img1 {
  width: 8rem;
  height: 13.90667rem;
  background: url("http://img.ailinzn.com/static/4.png") no-repeat center;

}

@keyframes longer {
  0% {
    width: 8rem;
  }

  100% {
    width: 11rem;
  }
}

.img1:hover {
  width: 11rem;
  animation: longer 1s linear;
  animation-fill-mode: forwards;
}

.img2:hover {
  width: 11rem;
  animation: longer 1s linear;
  animation-fill-mode: forwards;
}

.img3:hover {
  width: 11rem;
  animation: longer 1s linear;
  animation-fill-mode: forwards;
}

.centerImg .img2 {
  width: 8rem;
  height: 13.90667rem;
  background: url("http://img.ailinzn.com/static/5.png") no-repeat center;
}

.centerImg .img3 {
  width: 8rem;
  height: 13.90667rem;
  background: url("http://img.ailinzn.com/static/6.png") no-repeat center;
}

.kuai {
  width: 6.02667rem;
  height: 7.62667rem;
  background: white;
  border-radius: 0.26667rem;
  box-shadow: 0.48rem 0rem 0.42667rem #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-right: 0.93333rem;

}


.plus {
  position: relative;
}

.mid {
  max-width: 80vw;
  display: flex;
  justify-content: space-between
}

.production {
  height: 0.48rem;
  font-size: 0.48rem;
  font-family: MiSans;
  font-weight: 400;
  color: #000000;
}

.more {
  width: 5.62667rem;
  height: 1.33333rem;
  line-height: 1.33333rem;
  border: 0.02667rem solid #949494;
  border-radius: 0.66667rem;
  font-size: 0.42667rem;
  font-family: MiSans;
  font-weight: 400;
  color: #141414;
  margin-top: 1.06667rem;
}

.list {
  width: 67vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.product1 {
  width: 12.6rem;
  height: 10.26667rem;
  border-radius: 0.77333rem;
  margin-right: 2.5rem;
  position: relative;
  overflow: hidden;
}

.productFlex {
  width: 86%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.product2 {
  width: 7.2rem;
  height: 7.26667rem;
  border-radius: 0.77333rem;
  position: relative;
  overflow: hidden;
}

.gotoApp {
  position: relative;
  width: 99vw;
  height: 15.33333rem;
}

.neiApp {
  position: absolute;
  top: 26%;
  right: 14%;
}

.appT {
  font-size: 48px;
  font-family: MiSans;
  font-weight: 400;
  color: #000000;
}

.buttons {
  margin-left: 3rem;
  margin-top: 1rem;
  border: 0.02667rem solid #648ffc;
  padding: 0.26667rem 0.53333rem;
  border-radius: 0.26667rem;
  width: 3.08rem;
  height: 0.88rem;
  line-height: 0.88rem;
  font-size: 18px;
  color: #648ffc;
}

.App {
  width: 100%;
  height: 100%;
}

.dep {
  font-size: 0.4rem;
  margin: 20px 0px;
  width: 45%;
  height: 1rem;
  color: #666666;
}

.listL {
  width: 13rem;
  height: 5.81333rem;
  border-radius: 0.26667rem;
  margin-right: 40px;
}

.listLTop {
  height: 100%;
  overflow: hidden;
  border-radius: 9px;
}

.listLBottom {
  margin-top: 0.6rem;
  color: #000000;
  display: flex;
  height: 2.66667rem;
  flex-direction: column;
}

.shop {
  margin-bottom: 0.2rem;
  font-size: 0.42667rem;
  text-align: left;
  font-family: MiSans;
  font-weight: 400;
  color: #141414;
}

.listB {
  margin-top: 0;
  width: 57vw;
  flex-direction: row;
  justify-content: space-between;
}

.titleImage {
  width: 7.05333rem;
  height: 3.06667rem;
  font-size: 0.42667rem;
  font-family: MiSans;
  font-weight: 400;
  color: #939393;
  line-height: 0.64rem;
  overflow: hidden;
  border-radius: 8px;
}

.time {
  font-size: 0.3rem;
  color: #999999;
}

.detail {
  position: absolute;
  background: rgb(30 28 28 / 92%);
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: .5rem;
  justify-content: center;
  align-items: center;
  display: none;
}

.ppp:hover .detail {
  display: block;
  animation: upTo 1s;
  animation-fill-mode: forwards;
}

@keyframes upTo {
  from {
    display: none;
    top: 100%
  }

  to {
    display: block;
    top: 0%
  }
}

.cen {
  display: flex;
  height: 100%;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.names {
  margin-bottom: 10px;
}

.briefs {
  font-size: 16px;
}

.abouts {
  background: url("http://img.ailinzn.com/static/bg1.png") no-repeat center;
  width: 99vw;
  margin: 0 auto;
  padding-bottom: 3rem
}
</style>
