import Vue from 'vue'
import VueRouter from 'vue-router'
import aboutUs from '../views/aboutUsIndex/index.vue'
import cases from '../views/caseIndex/index.vue'
import homeIndex from '../views/homeIndex/index.vue'
import details from '../views/newsIndex/details.vue'
import News from '../views/newsIndex/index.vue'
import product from '../views/productIndex/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeIndex',
    component: homeIndex
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/details',
    name: 'details',
    component: details
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/cases',
    name: 'cases',
    component: cases
  },
  {
    path: '/shopAddress',
    name: 'shopAddress',
    component: () => import(/* webpackChunkName: "about" */ '../views/shopAddress/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
