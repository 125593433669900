<template>
  <div>
    <div class="gotoApp">
      <img src="http://img.ailinzn.com/static/banner2.png" alt="" class="App">
      <div class="lxwm">
        <div>关于我们</div>
      </div>
    </div>
    <div class="list">
      <div class="pro">
        <div class="titleImage" v-for="item in list" @click="showDialog(item)">
          <img :src="item.thumbnail" alt="" class="App">
          <div class="detail">
            <div class="cen">
              <p class="names">{{ item.bannerName }}</p>
              <p class="briefs">{{ item.brief }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <img :src="imgs" alt="" class="inputImg">
    </el-dialog>
    <footterComponent></footterComponent>
  </div>
</template>
<script>
import footterComponent from '../../components/footterComponent.vue';

export default {
  components: {
    footterComponent,
  },
  data() {
    return {
      url: 'http://api-smart.ailinzn.cn',
      list: [],
      dialogVisible: false,
      title: '',
      imgs: ''
    };
  },
  created() {
    this.getNews()
  },
  methods: {
    showDialog(item) {
      let self = this
      self.title = item.bannerName
      self.imgs = item.thumbnail
      self.dialogVisible = true
    },
    getNews() {
      let self = this
      this.$axios({
        method: 'POST',
        url: self.url + '/web/show/showList',
        data: {
          type: '2'
        }
      })
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            self.list = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style scoped>
.gotoApp {
  height: 9.33333rem;
  position: relative
}

.lxwm {
  position: absolute;
  top: 0;
  background: rgb(30 28 28 / 39%);
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 1.53333rem;
  font-family: MiSans;
  display: flex;
  justify-content: center;
  align-items: center;
}

.detail {
  position: absolute;
  background: rgb(30 28 28 / 92%);
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: .3rem;
  justify-content: center;
  align-items: center;
  display: none;
}

.titleImage:hover .detail {
  display: block;
  animation: upTo 1s;
  animation-fill-mode: forwards;
}

@keyframes upTo {
  from {
    display: none;
    top: 100%
  }

  to {
    display: block;
    top: 0%
  }
}

.App {
  width: 100%;
  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.pro {
  width: 76vw;
  display: flex;
  flex-wrap: wrap;
  min-height: 34vh;
}

.shop {
  margin-bottom: 0.2rem;
  font-size: 0.42667rem;
  text-align: left;
  font-family: MiSans;
  font-weight: 400;
  color: #141414;
}

.titleImage {
  width: 6.05333rem;
  height: 6.06667rem;
  margin-right: .8rem;
  margin-bottom: .8rem;
  font-size: 0.42667rem;
  font-family: MiSans;
  font-weight: 400;
  color: #939393;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.time {
  font-size: 0.3rem;
  color: #999999;
}

.cen {
  display: flex;
  height: 100%;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.names {
  margin-bottom: 10px;
}

.briefs {
  font-size: 16px;
}

.inputImg {
  max-width: 100%;
}
</style>
